import type {
  GetWaitlistsResponseBody,
  GetWaitlistsResponseItem,
} from '@purple-dot/main/src/presentation-layer/custom-server/api/public/views/get-waitlists-response';
import type { ShopifyApi, ShopifyProduct } from './shopify-api';

import { getItem } from '@purple-dot/browser-sdk/src/libraries/local-storage';
import { captureError } from './debug/capture-error';
import {
  fetchAvailability,
  fetchWaitlists,
} from './purple-dot-integration/backend';

export interface VariantState {
  state: PreorderState;
  waitlist: GetWaitlistsResponseItem | null;
  product: ShopifyProduct | null;
}

export enum PreorderState {
  Available = 'AVAILABLE',
  Preorder = 'PRE_ORDER',
  SoldOut = 'SOLD_OUT',
  Unknown = 'UNKNOWN',
}

/**
 * Given a product identifier such as handle or ids, provide
 * an easy way to access whether there are available
 * waitlist spots for that product
 */
export class WaitlistAvailability {
  private readonly shopifyApi: ShopifyApi;
  private readonly productMap: Record<string, ShopifyProduct> = {};
  private readonly supportedMarkets: string[] | undefined;
  private waitlists: GetWaitlistsResponseItem[] | null = null;
  private isInSupportedMarket: boolean | undefined;

  onUnsupportedMarket: (() => void) | undefined;
  enableWaitlists: () => boolean | Promise<boolean>;

  constructor({
    shopifyApi,
    supportedMarkets,
    onUnsupportedMarket,
    enableWaitlists,
  }: {
    shopifyApi: ShopifyApi;
    supportedMarkets?: string[];
    onUnsupportedMarket?: () => void;
    enableWaitlists?: () => boolean;
  }) {
    this.shopifyApi = shopifyApi;
    this.supportedMarkets = supportedMarkets;
    this.onUnsupportedMarket = onUnsupportedMarket;
    this.enableWaitlists = enableWaitlists || (() => true);
  }

  async preload(handle?: string) {
    try {
      await this._fetchWaitlists();

      if (handle) {
        await this._fetchProduct(handle);
      }
    } finally {
      document.querySelector('#pd-preload-style')?.remove();
    }
  }

  async getWaitlist({
    handle,
    productId: passedId,
  }: {
    handle: string;
    productId?: number;
  }) {
    let productId: number;
    if (passedId) {
      productId = passedId;
    } else {
      const product = await this._fetchProduct(handle);
      productId = product.id;
    }

    const waitlist = await this._fetchWaitlistForProductId(productId);
    return waitlist;
  }

  async getVariantAvailability(variantId: number) {
    const productAvailability = await fetchAvailability(variantId);

    const variantAvailability = productAvailability?.variants.find(
      (v) => v.id === `${variantId}`
    );

    return variantAvailability;
  }

  async getProduct({
    handle,
    variantId,
  }: {
    handle?: string | null;
    variantId?: number | null;
  }) {
    if (handle) {
      return await this._fetchProduct(handle);
    }

    if (variantId) {
      const availability = await fetchAvailability(variantId);
      if (availability?.product_code) {
        return await this._fetchProduct(availability.product_code);
      }
    }

    return null;
  }

  private async _fetchWaitlistForProductId(productId: number) {
    const waitlists = await this._fetchWaitlists();

    if (!waitlists) {
      return null;
    }

    return waitlists.find((w) => w.product.id === `${productId}`) ?? null;
  }

  private async _fetchProduct(handle: string): Promise<ShopifyProduct> {
    if (this.productMap[handle]) {
      return this.productMap[handle];
    }

    const product = await this.shopifyApi.fetchProduct(handle);
    this.productMap[handle] = product;
    return product;
  }

  async isPdSellingPlan(sellingPlanId: number) {
    const waitlists = await this._fetchWaitlists();

    if (!waitlists) {
      return false;
    }

    return waitlists.some(
      (w) =>
        w.selling_plan_id &&
        getSellingPlanId(w.selling_plan_id) === sellingPlanId
    );
  }

  private async _fetchWaitlists(): Promise<GetWaitlistsResponseBody | null> {
    if (!this.waitlists) {
      this.waitlists = await fetchWaitlists();
    }
    return this.waitlists;
  }

  async waitlistsEnabled() {
    let loopIsActive = false;
    try {
      loopIsActive = !!window?.LoopOnstore?.isActive();
    } catch (_err) {}

    if (loopIsActive) {
      return false;
    }

    const overrideRestrictedMarkets = getItem('overrideRestrictedMarkets');
    if (overrideRestrictedMarkets) {
      return true;
    }

    if (this.isInSupportedMarket === undefined) {
      try {
        this.isInSupportedMarket = await this._checkSupportedMarkets();
      } catch (err) {
        void captureError(err as Error);
        this.isInSupportedMarket = false;
      }
    }

    if (!this.isInSupportedMarket) {
      return false;
    }

    try {
      return await this.enableWaitlists();
    } catch (err) {
      void captureError(err as Error);
      return false;
    }
  }

  private async _checkSupportedMarkets() {
    if (this.supportedMarkets) {
      const data = await this.shopifyApi.browsingContextSuggestions();

      if (data?.detected_values.country.handle) {
        const isSupported = this.supportedMarkets.includes(
          data.detected_values.country.handle
        );

        if (!isSupported && this.onUnsupportedMarket) {
          this.onUnsupportedMarket();
        }

        return isSupported;
      }

      return true;
    }

    return true;
  }
}

export function getSellingPlanId(graphqlSellingPlanId: string) {
  const id = graphqlSellingPlanId.split('/').pop();

  if (!id) {
    throw new Error(
      `No selling plan id found from graphQL id ${graphqlSellingPlanId}`
    );
  }
  return Number.parseInt(id, 10);
}
