export function disallowCheckout({ shopifyCart }) {
  if (!shopifyCart) {
    return false;
  }

  const { items } = shopifyCart;

  const cartHasNoPreorderItems = !items.some(isPreorderItem);
  if (cartHasNoPreorderItems) {
    return false;
  }

  const cartIsMixed = !items.every(isPreorderItem);
  if (cartIsMixed) {
    return true;
  }

  const someItemsNotCompatibleWithNativeCheckout = items.some(
    (item) => !isItemCompatibleWithNativeCheckout(item)
  );

  if (someItemsNotCompatibleWithNativeCheckout) {
    return true;
  }

  return false;
}

function isPreorderItem(item) {
  return !!item.properties?.__releaseId;
}

function isItemCompatibleWithNativeCheckout(item) {
  if (
    item.properties &&
    '__pdCheckoutRequired' in item.properties &&
    item.selling_plan_allocation?.selling_plan?.id
  ) {
    return !item.properties.__pdCheckoutRequired;
  }

  return false;
}
